.assistant-message {
  float: left;
  padding: 10px 15px;
  margin: 5px 5px 10px;
  border-radius: 20px 20px 20px 1px;
  background: #d3e3f8;
  color: #272a38;
  min-width: 40px;
  max-width: 290px;
}


.assistant-message li {
  margin-bottom: 2px;
}

