.input {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.input > form > input[type="text"] {
  font-family: monospace;
  font-size: 16px;
  border: 0;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: 1px solid #eee;
  box-shadow: none;
  box-sizing: border-box;
  opacity: 1;
  outline: none;
  padding: 16px 52px 16px 10px;
  width: 100%;
  -webkit-appearance: none;
}

.input > form > input:focus {
  outline: none;
}

.input > form > button {
  background-color: transparent;
  border: 0;
  border-bottom-right-radius: 10px;
  box-shadow: none;
  cursor: pointer;
  fill: #4a4a4a;
  opacity: 1;
  outline: none;
  padding: 14px 16px 12px 16px;
  position: absolute;
  right: 0;
  top: 5px;
}
