.sal-assistant {
  font-family: Calibri, "Gill Sans";
  border-radius: 10px; 
  box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.15);
  background: #f5f8fb;
  display: flex;
  flex-flow: column;
  width: 500px;
  overflow: hidden;
}
