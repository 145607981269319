/* this is to remove accordion blue outline on focus */
*:focus {
  box-shadow: none !important;
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: Calibri, "Gill Sans" !important;
}
.scrollable::-webkit-scrollbar {
  width: 1px;
  display: none;
  margin: 0;
  border-radius: 20px;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.rotate-image {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* intention blinking text */
.blink_intention {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.date-picker-container {
  /* background-color: red; */
  display: flex;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}
.year-container {
  /* flex-direction: row-reverse; */
  display: flex;
  position: relative;
  /* margin:0 15px; */
  outline: none;
  text-align: center;
}
.month-container {
  /* margin:0 15px; */
  outline: none;
  position: relative;
}
.day-container {
  /* margin:0 15px; */
  position: relative;
  outline: none;
}

.date-picker-selector {
  outline: none;
  padding: 0 20px;
  padding-left: 0;
  background-color: transparent;
  text-align: center;
  display: flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  /* background: ; */
}

.day-container::before,
.month-container::before,
.year-container::before {
  content: "⌄";
  display: block;
  position: absolute;
  top: -2px;
  right: 0px;
  /* z-index: -1; */
}
.print-only {
  display: none;
}

@media print {
  .remove-on-print {
    visibility: hidden;
  }
  .print-only {
    display: block;
    /* visibility: visible; */
  }
}

.actions-table th,
.actions-table td {
  border: 2px solid #454545;
}

.ql-editor .ql-size-huge {
  font-size: 2.5em;
}

g {
  outline: none !important;
}

g:focus {
  outline: none !important;
}

.recharts-legend-wrapper {
  width: 430px !important;
}

.recharts-legend-item-text {
  color: black !important;
}
