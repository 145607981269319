.section {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
}

.lineSeparatorContainer {
  height: 1px;
  display: flex;
  justify-content: center;
}

.lineSeparator {
  height: 1px;
  background-color: #d0d0d0;
  width: 100%;
  max-width: 20rem;
}

.sectionTitle {
  color: var(--chakra-colors-brand-900);
  text-transform: capitalize;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 1rem 0;
}

.sections {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.videosContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.videoContainer {
  display: flex;
  gap: 1rem;
}

.playerWrapper {
  width: 50%;
  position: relative;
  padding-top: 28.125%; /* 16:9 aspect ratio for 50% width */
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.videoContent {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.videoTitleContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.videoTitle {
  font-weight: 600;
  font-size: 1rem;
  margin-right: 0.5rem;
  flex: 1;
}

.infoIcon {
  flex-shrink: 0;
  cursor: pointer;
}

.videoLecturer {
  color: grey;
  font-size: 0.8125rem;
  margin-bottom: 0.5rem;
}

.videoDescription {
  color: grey;
  font-size: 0.875rem;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.seeMoreBtn {
  color: var(--chakra-colors-brand-900);
  font-size: 0.875rem;
  font-weight: 600;
  margin-top: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.seeMoreBtn:focus {
  outline: none;
  border: none;
}

.seeMoreBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .videoContainer {
    flex-direction: row;
    align-items: flex-start;
    gap: 0rem;
  }

  .playerWrapper {
    width: 40%;
    padding-top: 22.5%; /* Maintain 16:9 aspect ratio for 40% width */
    margin: auto;
  }

  .videoContent {
    width: 60%;
    padding-left: 1rem;
  }

  .videoTitleContainer {
    flex-direction: row;
    align-items: center;
  }

  .videoTitle {
    font-size: 0.9rem;
  }

  .videoDescription {
    font-size: 0.8rem;
    -webkit-line-clamp: 3;
  }
}

@media (min-width: 769px) {
  .videosContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }
}