/* .messages {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
} */

.message-container {
  width: 100%;
}

.ar-message {
  text-align: right !important;
  direction: rtl !important;
}

.en-message {
  text-align: left !important;
  direction: ltr !important;
}