@keyframes fadeInOut {
    0% { opacity: 0; }
    25% { opacity: 0.5; }
    50% { opacity: 1; }
    75% { opacity: 0.5; }
    100% { opacity: 0; }
  }
  
  .fadeInOut {
    animation: fadeInOut 2s linear infinite; /* Adjust the duration as per your preference */
  }
  